<template>
  <div class="content">
    <Nav></Nav>
    <Navbj></Navbj>

    <div class="cont_inform">
      <div><img src="https://qiniu.fanyu889.com/cxmx/assets/pcimg/9.png" alt="">首页 >  <span>关于我们</span></div>
    </div>
    <div class="cont_about">
      <div class="about_list" v-for="(item,index) in list" :key="index">
         <div v-if="item.type == 1" class="item_left">
           <div class="left">
             <img :src="item.img" alt="">
           </div>
            <div class="right">
              <p>{{item.title}}<img src="https://qiniu.fanyu889.com/cxmx/assets/pcimg/1.png" alt=""></p>
              <p>{{item.uk}}</p>
              <p>{{item.subtitle}}</p>
              <p v-html="item.info"></p>
            </div>



         </div>
         <div v-else class="item_left">
           <div class="right">
             <p>{{item.title}}<img src="https://qiniu.fanyu889.com/cxmx/assets/pcimg/1.png" alt=""></p>
             <p>{{item.uk}}</p>
             <p>{{item.subtitle}}</p>
             <p v-html="item.info"></p>
           </div>
           <div class="left">
             <img :src="item.img" alt="">
           </div>

        </div>
      </div>
<!--       <div class="about_xz">-->
<!--         <img src="https://qiniu.fanyu889.com/cxmx/assets/pcimg/21.png" alt="">-->
<!--       </div>-->
    </div>
       
    <Btm></Btm>
  </div>
</template>

<script>
import Nav from "@/components/pc-Nav/index"
import Btm from "@/components/pc-Btmfooter/index"
import {gywm} from "@/api/request/all";
import Navbj from "@/components/pc-Nav/nav_bj";
import {mapState} from "vuex";
export default {
  name: "about",
  data(){
    return{
      list:[]
    }
  },
  components:{
    Nav,
    Navbj,
    Btm
  },
  mounted() {
    this.about()
  },
  computed: {
    ...mapState(['user','seo']),
  },
  metaInfo() {
    return {
      title: this.seo[4].title || this.user.indextitle,
      meta: [
        { name: 'description', content: this.seo[4].description ||this.user.drp},
        { name: 'keywords', content:this.seo[4].keywords ||this.user.seo}
      ]
    };
  },
  methods:{
    about(){
      gywm().then((res)=>{
        if (res.status == 1){
          for (let i=0; i<res.data.length; i++){
            res.data[i].info= this.convertNewLinesToBrTags(res.data[i].info);
          }
         this.list=res.data;
        }

      })
    },
    //正则转换
    convertNewLinesToBrTags(text) {
      return text.replace(/(\r\n|\n|\r)/gm, "<br/>");
    },
  }
}
</script>

<style scoped lang="scss">
.content{

  .cont_inform{
    width: 100%;
    height: 51px;
    background: #EEEEEE;
    div{
      width: 1243px;
      margin:0 auto;
      height: 51px;
      line-height: 51px;
      img{
        width: 16px;
        height: 20px;
        vertical-align: sub;
        margin-right: 9px;
        position: relative;
        top: -2px;
      }
      font-weight: 500;
      font-size: 18px;
      color: #666666;
      span{
        color: #222222;
      }
    }
  }
  .cont_about{
    width: 100%;
    background: #FFFFFF;
    padding: 66px 0;
   .about_list{
      width: 1243px;
      margin: 0 auto 90px;
     .item_left{
       min-height: 383px;
       display: flex;
       justify-content: space-between;
       .left{
         width: 596px;
         position: relative;
         img{
           width: 596px;
           height: 383px;
           border-radius: 8px;
           position: absolute;
           top: 50%;
           left: 0;
           transform: translateY(-50%);
         }
       }
        .right{
          width: 584px;
          p:nth-child(1){
            font-weight: 800;
            font-size: 24px;
            color: #222222;
            img{
              width: 48px;
              height: 18px;
              vertical-align: middle;
              margin-left: 11px;
              position: relative;
              top: -2px;
            }
          }
          p:nth-child(2){
            font-weight: 500;
            font-size: 18px;
            color: #999999;
            margin-top: 12px;
          }
          p:nth-child(3){
            font-weight: bold;
            font-size: 20px;
            color: #222222;
            margin-top: 22px;

          }
          p:nth-child(4){
            font-weight: 500;
            font-size: 18px;
            color: #666666;
            line-height: 30px;
            margin-top: 10px;

          }
          p:nth-child(5){
            font-weight: 500;
            font-size: 18px;
            color: #666666;
            line-height: 30px;

          }
        }


     }
     .item_right{
       position: relative;
       min-height: 383px;
       img{
         width: 596px;
         height: 383px;
         border-radius: 8px;
         position: absolute;
         right: 0;
         top: 50%;
         transform: translateY(-50%);
       }
       .right_js{
         font-weight: 800;
         font-size: 24px;
         color: #222222;
         position: absolute;
         top: 0;
         left: 0px;
         img{
           width: 48px;
           height: 18px;
           vertical-align: middle;
           margin-left: 11px;
           position: relative;
           top: -2px;
         }
       }
       p:nth-child(3){
         font-weight: 500;
         font-size: 18px;
         color: #999999;
         position: absolute;
         left: 0px;
         top: 39px;
       }

       .right_div{
         position: absolute;
         left: 0;
         top: 92px;

           margin-bottom: 58px;
           p:nth-child(1){
             font-weight: bold;
             font-size: 20px;
             color: #222222;
             width: 584px;
           }
           p:nth-child(2){
             font-weight: 500;
             width: 584px;
             font-size: 18px;
             color: #666666;
             line-height: 30px;
           }
         }
     }
    }
    .about_xz{
      width: 300px;
      height: 66px;
      margin: 0 auto;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }

}
</style>